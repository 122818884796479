import React, { useState } from 'react'
import AeditLink from 'services/link'
import { Icon } from 'components/icon/Icon'
import ProfileLink from 'components/profile_link'
import LogoutLink from 'components/logout'

const MobileMenu = ({
  openMenu,
  mobileMenuOpen,
  setMobileMenuOpen,
  setSearchOpen,
  setSearchClosed,
  currentUser,
  toggleCandidateAuth,
  toggleProviderAuth,
}) => {
  const [solutionsOpen, setSolutionsOpen] = useState(false)
  const [providerOptionOpen, setProviderOptionsOpen] = useState(false)

  return (
    <div id="mobile-menu" className="text-black md:hidden">
      <Icon
        name={mobileMenuOpen || openMenu === 'search' ? 'close' : 'hamburger'}
        className="cursor-pointer"
        stroke="white"
        onClick={() => {
          setMobileMenuOpen(!mobileMenuOpen)
          setSearchClosed()
        }}
      />

      {mobileMenuOpen && (
        <div className="fixed top-mobile-nav md:top-nav inset-x-0 bottom-0 bg-white md:hidden">
          <ul className="p-8">
            <li className="t-overline">
              <span
                className="flex items-center mb-8 cursor-pointer"
                onClick={() => setSolutionsOpen(!solutionsOpen)}>
                <p className="t-overline mr-2">SOLUTIONS</p>
                <Icon
                  name="chevron-down"
                  className={`caret ${
                    solutionsOpen ? '-rotate-180' : ''
                  } transition-all duration-500`}
                />
              </span>
              {solutionsOpen && (
                <ul className="ml-4">
                  <li className="flex mb-8">
                    <AeditLink className="flex items-center cursor-pointer" pathname="/concerns">
                      <Icon name="heart" className="flex" />
                      <p className="t-overline ml-2">CONCERNS</p>
                    </AeditLink>
                  </li>
                  <li className="flex mb-8">
                    <AeditLink className="flex items-center cursor-pointer" pathname="/procedures">
                      <Icon name="clipboard" className="flex" />
                      <p className="t-overline ml-2">PROCEDURES</p>
                    </AeditLink>
                  </li>
                </ul>
              )}
            </li>
            <li className="flex mb-8">
              <AeditLink className="t-overline block cursor-pointer" pathname="/providers">
                FIND A PROVIDER
              </AeditLink>
            </li>
            <li className="flex mb-8">
              <AeditLink className="t-overline block cursor-pointer" pathname="/aedition">
                AEDITION
              </AeditLink>
            </li>

            <div className="h-px w-full bg-light-gray my-8" />

            <li className="flex mb-8 cursor-pointer" onClick={() => setSearchOpen()}>
              <Icon name="search" />
              <p className="t-overline ml-2">SEARCH</p>
            </li>

            {currentUser ? (
              <li onClick={() => setProviderOptionsOpen(!providerOptionOpen)}>
                <span className="flex items-center">
                  <Icon name={currentUser.role_name === 'provider' ? 'med-cross' : 'person'} />
                  <p className="t-overline ml-2">{currentUser.first_name}</p>
                  <Icon
                    name="chevron-down"
                    className={`caret ${
                      providerOptionOpen ? '-rotate-180' : ''
                    } transition-all duration-500`}
                  />
                </span>

                {providerOptionOpen && (
                  <ul>
                    <li className="flex ml-4 mt-8">
                      <ProfileLink className="flex cursor-pointer">
                        <Icon name="pencil" />
                        <p className="t-overline ml-2">Manage Profile</p>
                      </ProfileLink>
                    </li>
                    <li className="flex ml-4 mt-8 cursor-pointer">
                      <LogoutLink callback={() => setMobileMenuOpen(false)} className="flex">
                        <Icon name="log-out" />
                        <p className="t-overline ml-2">Log Out</p>
                      </LogoutLink>
                    </li>
                  </ul>
                )}
              </li>
            ) : (
              <>
                <li className="flex mb-8 cursor-pointer" onClick={toggleCandidateAuth}>
                  <Icon name="person" />
                  <p className="t-overline ml-2">I'M A CANDIDATE</p>
                </li>

                <li className="mb-8">
                  <span
                    className="flex items-center mb-8 cursor-pointer"
                    onClick={() => setProviderOptionsOpen(!providerOptionOpen)}>
                    <Icon name="med-cross" />
                    <p className="t-overline mx-2">I'M A PROVIDER</p>
                    <Icon
                      name="chevron-down"
                      className={`caret ${
                        providerOptionOpen ? '-rotate-180' : ''
                      } transition-all duration-500`}
                    />
                  </span>

                  {providerOptionOpen && (
                    <ul className="ml-4">
                      <li className="flex mb-8 cursor-pointer">
                        <AeditLink className="flex items-center" pathname="/for-providers">
                          <Icon name="map-pin" className="svg-icon mr-2" />
                          <p className="t-overline">LIST YOUR PRACTICE</p>
                        </AeditLink>
                      </li>
                      <li className="flex items-center cursor-pointer" onClick={toggleProviderAuth}>
                        <Icon name="med-cross" className="svg-icon mr-2" />
                        <p className="t-overline">PROVIDER LOGIN</p>
                      </li>
                    </ul>
                  )}
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

export default MobileMenu
